<template>
    <el-container class="page-container">
        <el-main class="page-main" style="padding: 0 20px">
            <br>
            <el-form label-position='left' ref="searchForm">
                <el-row :gutter="20">
                    <el-col :xs="12" :sm="12" :md="12" :lg="6">
                        <el-form-item prop="status">
                            <el-input placeholder="请输入内容" class="inner-input" v-model="pageForm.searchKeyword"
                                      @keyup.enter.native="doSearch">
                                <el-select v-model="pageForm.searchName" slot="prepend" placeholder="查询类型"
                                           style="width: 120px;">
                                    <el-option label="类型编码" value="goodsType"></el-option>
                                    <el-option label="类型名称" value="name"></el-option>
                                </el-select>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="24">
                        <el-form-item label-width="0">
                            <el-button type="primary" size="medium" @click="doSearch">
                                <i class="cw-icon cw-icon-sousuo"></i>搜索
                            </el-button>
                            <el-button type="primary" size="medium" plain @click="doResetSearch">
                                <i class="cw-icon cw-icon-zhongzhi"></i>重置
                            </el-button>
                            <el-button type="primary" size="medium" @click="openAdd"><i class="ti-plus"></i> 新增菜品
                            </el-button>
                            <el-button @click="handleChangeState(1)" type="success" :disabled="!selectData.length"
                                       size="medium">批量上架
                            </el-button>
                            <el-button @click="handleChangeState(-1)" type="danger" :disabled="!selectData.length"
                                       size="medium">批量下架
                            </el-button>
                        </el-form-item>
                    </el-col>

                </el-row>
            </el-form>
            <el-table
                    :data="tableData"
                    :key="tableKey"
                    @sort-change='pageOrder'
                    size="small"
                    header-align="center"
                    style="width: 100%"
                    @selection-change="handleSelectionChange"
                    :default-sort="{prop: 'opAt', order: 'descending'}"
            >
                <el-table-column
                        type="selection"
                        width="55">
                </el-table-column>
                <el-table-column type="expand">
                    <template slot-scope="scope">
                        <el-form label-position="left" label-width="100px">
                            <el-form-item label="材料">
                                <el-tag v-for="(material,index) in splitMaterial(scope.row.materialIds)" :key="index"
                                        style="margin-right: 24px">{{filterMaterials(material.materialId).name}}:{{material.dosage}}-{{material.unit}}
                                </el-tag>
                            </el-form-item>
                            <el-form-item label="规格">
                                <el-tag v-for="(sku,index) in scope.row.skuList" :key="index"
                                        style="margin-right: 24px">
                                    规格名称：{{sku.name}}--售价{{sku.price}}--市场价：{{sku.marketPrice}}
                                </el-tag>
                            </el-form-item>
                            <el-form-item label="标签">
                                <el-tag v-for="(tag,index) in scope.row.tagList" :key="index"
                                        style="margin-right: 24px">{{tag.name}}
                                </el-tag>
                            </el-form-item>
                        </el-form>
                    </template>
                </el-table-column>
                <el-table-column label="菜品类型" header-align="left" prop="goodsType" :formatter="fomatGoodsType"
                                 :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column label="菜品编码" header-align="left" prop="goodsType"
                                 :show-overflow-tooltip="true"></el-table-column>
                <el-table-column label="菜品名称" header-align="left" prop="name"
                                 :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column label="封面小图" header-align="left"
                                 :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <img :src="scope.row.picUrl" :alt="scope.row.picUrl" class="shop-image">
                    </template>
                </el-table-column>
                <el-table-column label="状态" header-align="left" prop="isOnSale" :formatter="fomatData"
                                 :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column label="售价(￥)" header-align="left" prop="price"
                                 :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column label="市场价(￥)" header-align="left" prop="marketPrice"
                                 :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column label="已售出(份)" header-align="left" prop="saledNum"
                                 :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column label="库存(份)" header-align="left" prop="storeNum"
                                 :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column label="每日库存(份)" header-align="left" prop="storeDefault"
                                 :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column label="商品有效期" header-align="left" prop="deadline"
                                 :show-overflow-tooltip="true">
                </el-table-column>
                <el-table-column
                        prop="id"
                        label="操作"
                        fixed="right"
                        width="115">
                    <template slot-scope="scope">
                        <el-dropdown @command="dropdownCommand">
                            <el-button size="mini">
                                <i class="el-icon-setting"></i>
                                操作
                                <span class="el-icon-arrow-down"></span>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :command="{type:'edit',row:scope.row}">
                                    菜品修改
                                </el-dropdown-item>
                                <el-dropdown-item :command="{type:'sku',row:scope.row}">
                                    规格修改
                                </el-dropdown-item>
                                <el-dropdown-item :command="{type:'delete',row:scope.row}">
                                    删除
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <br>
            <el-pagination
                    background
                    @size-change="pageSizeChange"
                    @current-change="pageNumberChange"
                    :current-page="pageForm.pageNumber"
                    :page-sizes="[10, 20, 30, 50]"
                    :page-size="pageForm.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageForm.totalCount">
            </el-pagination>
        </el-main>
        <el-dialog
                title="新增"
                fullscreen
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="addDialogVisible"
                :before-close="handleClose">
            <add-foods v-if="addDialogVisible" @success="handleClose(1)" @cancel="handleClose"/>
        </el-dialog>
        <el-dialog
                title="修改"
                fullscreen
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :visible.sync="editDialogVisible"
                :before-close="handleClose">
            <edit-foods v-if="editDialogVisible" :id="row.id" @success="handleClose(1)" @cancel="handleClose"/>
        </el-dialog>
        <form-dialog fullscreen @close="handleClose('skuForm')" title="规格编辑" :dialogVisible="skuDialogVisible">
            <template slot="content">
                <el-form :model="skuForm" ref="skuForm" size="small" label-width="100px">
                    <el-row :gutter="20">
                        <el-card>
                            <div slot="header">
                                <el-button size="small" type="primary"
                                           @click="handleAddSku">新增
                                </el-button>
                            </div>
                            <el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="8" v-for="(sku,index) in skuForm.skuList"
                                    :key="index">
                                <el-card>
                                    <el-row :gutter="20">
                                        <el-col>
                                            <el-form-item
                                                    :key="index"
                                                    :prop="'skuList.' + index + '.name'"
                                                    :rules="{
                                                      required: true, message: '请输入规格名称', trigger: 'blur'
                                                }"
                                                    label="规格名称">
                                                <el-input maxlength="100" placeholder="请输入规格名称"
                                                          v-model="sku.name"
                                                          auto-complete="off" tabindex="10" type="text"></el-input>
                                            </el-form-item>
                                        </el-col>

                                        <el-col>
                                            <el-form-item
                                                    :key="index"
                                                    :prop="'skuList.' + index + '.price'"
                                                    :rules="{
                                                      required: true, message: '请输入售价', trigger: 'blur'
                                                }"
                                                    label="售价(￥)">
                                                <el-input maxlength="100" placeholder="请输入售价"
                                                          v-model="sku.price"
                                                          auto-complete="off" tabindex="10" type="text"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col>
                                            <el-form-item
                                                    :key="index"
                                                    :prop="'skuList.' + index + '.marketPrice'"
                                                    :rules="{
                                                      required: true, message: '请输入市场价', trigger: 'blur'
                                                }"
                                                    label="市场价(￥)">
                                                <el-input maxlength="100" placeholder="请输入市场价"
                                                          v-model="sku.marketPrice"
                                                          auto-complete="off" tabindex="10" type="text"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col>
                                            <el-form-item
                                                    :key="index"
                                                    :prop="'skuList.' + index + '.storeDefault'"
                                                    :rules="{
                                                      required: true, message: '请输入每日库存', trigger: 'blur'
                                                }"
                                                    label="每日库存">
                                                <el-input maxlength="100" placeholder="请输入每日库存"
                                                          v-model="sku.storeDefault"
                                                          auto-complete="off" tabindex="10" type="text"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col>
                                            <el-form-item
                                                    :key="index"
                                                    :prop="'skuList.' + index + '.saledNum'"
                                                    :rules="{
                                                      required: true, message: '请输入已售数量', trigger: 'blur'
                                                }"
                                                    label="已售量">
                                                <el-input maxlength="100" placeholder="请输入已售数量"
                                                          v-model="sku.saledNum"
                                                          auto-complete="off" tabindex="10" type="text"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col>
                                            <el-form-item
                                                    :key="index"
                                                    :prop="'skuList.' + index + '.storeNum'"
                                                    :rules="{
                                                      required: true, message: '请输入库存', trigger: 'blur'
                                                }"
                                                    label="库存">
                                                <el-input maxlength="100" placeholder="请输入库存"
                                                          v-model="sku.storeNum"
                                                          auto-complete="off" tabindex="10" type="text"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col>
                                            <el-form-item
                                                    :key="index"
                                                    :prop="'skuList.' + index + '.isOnSale'"
                                                    :rules="{
                                                      required: true, message: '请选择上架状态', trigger: 'blur'
                                                }"
                                                    label="上架状态">
                                                <el-radio v-model="sku.isOnSale" :label="1">上架</el-radio>
                                                <el-radio v-model="sku.isOnSale" :label="-1">下架</el-radio>
                                            </el-form-item>
                                        </el-col>
                                        <el-col>
                                            <el-form-item
                                                    label="封面大图">
                                                <el-upload
                                                        style="width: 72px;height: 72px"
                                                        class="avatar-uploader"
                                                        :action="action"
                                                        :show-file-list="false"
                                                        :headers="{'c-token':token}"
                                                        name="filedata"
                                                        :on-success="(response, file, fileList)=>{return uploadPicUrlSuccess(response, file, fileList,index)}">
                                                    <img v-if="sku.picUrl" :src="sku.picUrl" class="avatar-img">
                                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                                </el-upload>
                                            </el-form-item>
                                        </el-col>
                                        <el-col>
                                            <el-form-item
                                                    label="封面小图">
                                                <el-upload
                                                        style="width: 72px;height: 72px"
                                                        class="avatar-uploader"
                                                        :action="action"
                                                        :show-file-list="false"
                                                        :headers="{'c-token':token}"
                                                        name="filedata"
                                                        :on-success="(response, file, fileList)=>{return uploadMinPicSuccess(response, file, fileList,index)}">
                                                    <img v-if="sku.minPic" :src="sku.minPic" class="avatar-img">
                                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                                </el-upload>
                                            </el-form-item>
                                        </el-col>
                                        <el-col style="display: flex;justify-content: flex-end">
                                            <el-button size="small" @click="handleDeleteSku(index)">删除</el-button>
                                        </el-col>
                                    </el-row>
                                </el-card>
                                <br>
                            </el-col>

                        </el-card>
                    </el-row>
                    <br>
                    <div class="flex flex-direction-row-reverse btn-group">
                        <el-button @click="handleClose('skuForm')">取消</el-button>
                        <el-button type="primary" @click="skuEdit">确定</el-button>
                    </div>
                </el-form>
            </template>
        </form-dialog>
    </el-container>

</template>

<script>
import formDialog from '../../components/formDialog'
import * as foodManager from '../../api/foodManager'
import {foodSiteData} from "../../api/storeArea";
import upload from '../../components/upload'
import moment from 'moment'
import {uploadImg} from "../../api/activeManage";

import addFoods from "@/views/foodsManage/components/addFoods";
import editFoods from "@/views/foodsManage/components/editFoods";
import _ from 'loadsh'

const statusOptions = [
    {
        label: "上架",
        value: 1
    },
    {
        label: '下架',
        value: -1
    }
]
import {mapGetters} from 'vuex'

const action = process.env.VUE_APP_BASEAPI + 'open/file/upload/image'
export default {
    name: "foodsManage",
    components: {formDialog, upload, addFoods, editFoods},
    data() {
        return {
            action,
            tableKey: '',
            addDialogVisible: false,
            editDialogVisible: false,
            skuDialogVisible: false,
            selectData: [],
            pageForm: {
                searchName: "",
                searchKeyword: "",
                pageNumber: 1,
                pageSize: 10,
                totalCount: 0,
                pageOrderName: "opAt",
                pageOrderBy: "descending"
            },
            tableData: [],
            skuForm: {
                goodsId: '',
                skuList: [
                    {
                        name: '',
                        isOnSale: 1,
                        price: '',
                        marketPrice: '',
                        minPic: '',
                        picUrl: '',
                        storeNum: '',
                        storeDefault: '',
                        saledNum: '',
                        tagIds: ''
                    }
                ]
            },
            formData: {
                goodsType: '',
                name: '',
                picUrl: '',
                price: '',
                marketPrice: '',
                saledNum: '',
                storeNum: '',
                deadline: '',
                siteId: '',
                code: '',
                isOnSale: 1,
                sortNum: 0,
                description: ''
            },
            formRules: {
                siteId: [
                    {required: true, message: '请选择商品类型', trigger: 'blur'}
                ],
                description: [
                    {required: true, message: '请输入菜品描述', trigger: 'blur'}
                ],
                goodsType: [
                    {required: true, message: '请选择商品类型', trigger: 'blur'}
                ],
                name: [
                    {required: true, message: '请输入商品名称', trigger: 'blur'}
                ],
                isOnSale: [
                    {required: true, message: '请选择上架状态', trigger: 'blur'}
                ],
                sortNum: [
                    {required: true, message: '请输入排序编号', trigger: 'blur'}
                ],
                picUrl: [
                    {required: true, message: '请上传图片', trigger: 'blur'}
                ],
                price: [
                    {required: true, message: '请输入售价', trigger: 'blur'}
                ],
                marketPrice: [
                    {required: true, message: '请输入市场价', trigger: 'blur'}
                ],
                saledNum: [
                    {required: true, message: '请输入已售数量', trigger: 'blur'}
                ],
                storeNum: [
                    {required: true, message: '请输入库存数', trigger: 'blur'}
                ],
                deadline: [
                    {required: true, message: '请选择有效时间', trigger: 'blur'}
                ]
            },
            row:{},
            loading: false,
            storeOptions: [],
            statusOptions,
            goodsTypes: [],
            materialOptions: [],
            tagsOptions: [],

        }
    },
    computed: {
        ...mapGetters(['token']),
    },
    methods: {
        async handleChangeState(state) {
            try {
                await this.$confirm('此操作将修改菜品状态 ', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                var ids = [];
                this.selectData.forEach(function (val) {
                    ids.push(val.id);
                });
                let formData = {
                    ids: ids.toString(),
                    status: state
                }
                let data = await foodManager.dofinish(formData)
                this.$message({
                    message: data.msg,
                    type: 'success'
                });
                this.pageData();
            } catch (e) {

            }
        },
        async getTags() {
            try {
                let {data} = await foodManager.tagsDataNoPage()
                this.tagsOptions = data
                console.log('tagsDataNoPage', data)
            } catch (e) {

            }
        },
        handleAddSku() {
            let {skuList} = this.skuForm;
            skuList.push({
                name: '',
                isOnSale: 1,
                price: '',
                marketPrice: '',
                minPic: '',
                picUrl: '',
                storeNum: '',
                storeDefault: '',
            })
            this.skuForm.skuList = skuList

        },
        async handleDeleteSku(idx) {
            try {
                let {skuList} = this.skuForm;
                if (skuList[idx].id) {
                    await this.$confirm('此操作将删除此条规格 ', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    await foodManager.deleteSku(skuList[idx].id)
                }
                skuList.splice(idx, 1)
                this.skuForm.skuList = skuList
            } catch (e) {
                console.log(e)
            }
        },
        splitMaterial(materialIds) {
            return materialIds ? JSON.parse(materialIds): []
        },
        filterMaterials(ids) {
            let material = this.materialOptions.find(item => item.materialId === ids)
            return material ? material : {}
        },

        async uploadSuccess(e) {
            try {
                let file = e.target.files[0];
                let form = new FormData();
                form.append('filedata', file);
                let {data} = await uploadImg(form)
                this.formData.picUrl = data.url
                // this.$forceUpdate();
                console.log(this.formData)
            } catch (e) {
            }
            // this.formData.picUrl = data.url
            // this.$forceUpdate();
        },
        uploadMinPicSuccess(response, file, fileList, idx) {
            let skuList = this.skuForm.skuList;
            skuList[idx].minPic = response.data.url
            this.skuForm.skuList = skuList
            this.$forceUpdate();
        },

        uploadPicUrlSuccess(response, file, fileList, idx) {
            let skuList = this.skuForm.skuList;
            skuList[idx].picUrl = response.data.url
            this.skuForm.skuList = skuList
            this.$forceUpdate();
        },
        async getFoodMaterialList() {
            try {
                let {data} = await foodManager.getFoodMaterialList()
                this.materialOptions = data.goodsMaterialList
                console.log('getFoodMaterialList', data)
            } catch (e) {
                console.error(e)
            }
        },
        async getGoodsType() {
            try {
                let {data} = await foodManager.foodTypeData()
                this.goodsTypes = data.list
                console.log(data)
            } catch (e) {

            }
        },
        async remoteMethod(query) {
            if (query !== '') {
                this.loading = true;
                try {
                    let formData = {
                        searchName: 'name',
                        searchKeyword: query
                    }
                    let {data} = await foodSiteData(formData)
                    this.storeOptions = data.list
                } catch (e) {

                }
                this.loading = false;
            } else {
                this.storeOptions = [];
            }
        },
        handleClose(type) {

            if (type === 1) {
                this.doSearch()
            }
            this.addDialogVisible = false
            this.editDialogVisible = false
            this.skuDialogVisible = false


        },
        pageOrder(column) {//按字段排序
            this.pageForm.pageOrderName = column.prop;
            this.pageForm.pageOrderBy = column.order;
            this.pageData();
        },
        pageNumberChange(val) {//页码更新操作
            this.pageForm.pageNumber = val;
            this.pageData();
        },
        pageSizeChange(val) {//分页大小更新操作
            this.pageForm.pageSize = val;
            this.pageData();
        },
        doSearch() {
            this.pageForm.pageNumber = 1;
            this.pageData();
        },
        doResetSearch() {
            this.pageForm.searchName = '';
            this.pageForm.searchKeyword = '';
            this.doSearch()
        },
        async pageData() {//加载分页数据
            try {
                let formData = this.pageForm
                let {data} = await foodManager.goodsData(formData)
                this.tableData = data.list
                this.pageForm.totalCount = data.totalCount;
            } catch (e) {
                console.error(e)
            }
        },
        formatAt(val) {
            if (val > 0)
                return moment(val * 1000).format("YYYY-MM-DD HH:mm");
            return "";
        },
        handleSelectionChange(val) {
            this.selectData = val;
        },
        openAdd() {
            this.addDialogVisible = true;
        },
        doAdd() {
            this.$refs["addForm"].validate(async (valid) => {
                console.log(this.formData)
                if (valid) {//表单验证通过
                    try {
                        let formData = {}
                        for (let i in this.formData) {
                            if (this.formData[i] && i !== 'materialIds' && i !== 'tagIds') {
                                formData[i] = this.formData[i]
                            }
                        }
                        formData.id = ''
                        let materialIds = this.formData.materialIds ? this.formData.materialIds : []
                        let tagIds = this.formData.tagIds ? this.formData.tagIds : []
                        materialIds = materialIds.toString()
                        formData.tagIds = tagIds.toString()
                        let data = await foodManager.goodsAddDo(materialIds, formData)
                        this.$message({
                            message: data.msg,
                            type: 'success'
                        });
                        this.handleClose('addForm')
                        this.pageData();
                    } catch (e) {
                        console.error(e)
                    }
                }
            });
        },
        doEdit() {
            this.$refs["editForm"].validate(async (valid) => {
                if (valid) {//表单验证通过
                    try {
                        let formData = {}
                        for (let i in this.formData) {
                            if (this.formData[i] && i !== 'materialIds' && i !== 'tagIds') {
                                formData[i] = this.formData[i]
                            }
                        }
                        let tagIds = this.formData.tagIds ? this.formData.tagIds : []
                        let materialIds = this.formData.materialIds ? this.formData.materialIds : []
                        formData.tagIds = tagIds.toString()
                        materialIds = materialIds.toString()
                        let data = await foodManager.goodsEditDo(materialIds, formData)
                        this.$message({
                            message: data.msg,
                            type: 'success'
                        });
                        this.handleClose('editForm')
                        this.pageData();
                    } catch (e) {
                        console.error(e)
                    }
                }
            });
        },
        skuEdit() {
            this.$refs["skuForm"].validate(async (valid) => {
                if (valid) {//表单验证通过
                    try {
                        let skuForm = this.skuForm;
                        let formData = {
                            goodsId: skuForm.id,
                            skuList: JSON.stringify(skuForm.skuList)
                        }

                        console.log('ok', JSON.stringify(formData))
                        let data = await foodManager.skuEditDo(formData)
                        this.$message({
                            message: data.msg,
                            type: 'success'
                        });
                        this.handleClose('skuForm')
                        this.pageData();
                    } catch (e) {
                        console.error(e)
                    }
                }
            });
        },
        async delMore() {
            if (this.selectData.length == 0) {
                this.$message({
                    message: "请选择要删除的类型",
                    type: 'warning'
                });
                return false;
            }
            var ids = [];
            this.selectData.forEach(function (val) {
                ids.push(val.id);
            });
            try {
                await this.$confirm('此操作将删除 ' + ids.length + ' 菜品类型 ', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                let formData = {ids: ids.toString()};
                let data = await foodManager.foodTypeDeltes(formData)
                this.$message({
                    message: data.msg,
                    type: 'success'
                });
                this.pageData();
            } catch (e) {

            }
        },
        async dropdownCommand(command) {//监听下拉框事件
            if ("edit" === command.type) {
                this.row = _.cloneDeep(command.row)
                this.editDialogVisible = true;//打开编辑窗口
            }
            if ("sku" === command.type) {
                try {
                    this.skuDialogVisible = true
                    if (command.row.skuList.length) {
                        this.skuForm = command.row
                    } else {
                        command.row.skuList.push({
                            name: '',
                            isOnSale: 1,
                            price: '',
                            marketPrice: '',
                            minPic: '',
                            picUrl: '',
                            storeNum: '',
                            storeDefault: '',
                        })
                        this.skuForm = command.row
                    }

                    // let {data} = await foodManager.getGoodsEditDo(command.row.id)
                    // data.materialIds = data.materialIds ? data.materialIds.split(',') : []
                    // this.formData = data;//加载后台表单数据
                    // this.editDialogVisible = true;//打开编辑窗口
                } catch (e) {

                }
            }

            if ("delete" === command.type) {
                try {
                    await this.$confirm('删除后无法恢复，确定删除吗？', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                    let formData = {
                        isOnSale: 2,
                        id: command.row.id
                    }
                    let data = await foodManager.goodsEditDo('', formData)
                    this.$message({
                        message: data.msg,
                        type: 'success'
                    });
                    this.pageData();
                } catch (e) {
                }
            }
        },
        fomatData(row, column, cellValue, index) {
            let {isOnSale} = row
            let status = statusOptions.find(i => i.value === isOnSale)
            return status ? status.label : '--'
        },
        fomatGoodsType(row, column, cellValue, index) {
            let {goodsType} = row
            let type = this.goodsTypes.find(i => i.code === goodsType)
            return type ? type.name : '--'
        },
    },
    async created() {
        await this.getGoodsType()
        await this.getTags()
        this.getFoodMaterialList()
        this.pageData();
    }
}
</script>

<style lang="less" scoped>
.shop-image {
    width: 48px;
    height: 48px;
    border-radius: 4px;
}

.btn-group {
    button {
        margin-left: 20px;
    }

}

.el-input--prefix .el-input__inner {
    padding-left: 46px;
}

.el-input__prefix {
    left: 15px;
    color: #303133;

    i {
        font-style: normal;
    }
}

.icon-cursor {
    cursor: pointer;
    margin-right: 10px;

    &:hover {
        color: #348EED;
    }
}

.avatar-uploader {
    height: 70px;
    width: 70px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #dcdcdc;
    border-radius: 4px;
    cursor: pointer;

    &.el-upload:hover {
        border-color: #409EFF;
    }

    .cw-icon-tupian {
        font-size: 28px;
        color: #8c939d;
    }

    .el-upload {
        height: 100%;
        display: flex !important;
        justify-content: center;
        align-items: center;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar {
        height: 100%;
        width: 100%;
        display: block;
    }
}

</style>
