<template>
    <div id="edit"></div>
</template>

<script>
import E from 'wangeditor'

export default {
    name: "editor",
    props: {
        context: '',
        disable:{
            type:Boolean,
            default:false
        }
    },
    watch:{
        context(val){
            console.log(val)
            this.editor.txt.html(this.context)
        }
    },
    methods: {
        initEdit() {
            this.editor = new E('#edit')
            this.editor.config.menus = [
                'head',  // 标题
                'bold',  // 粗体
                'fontSize',  // 字号
                'fontName',  // 字体
                'italic',  // 斜体
                'underline',  // 下划线
                'strikeThrough',  // 删除线
                'foreColor',  // 文字颜色
                'backColor',  // 背景颜色
                // 'link',  // 插入链接
                'list',  // 列表
                'justify',  // 对齐方式
                'quote',  // 引用
                'emoticon',  // 表情
                // 'image',  // 插入图片
                // 'table',  // 表格
                // 'video',  // 插入视频
                // 'code',  // 插入代码
                'undo',  // 撤销
                'redo'  // 重复
            ]
            this.editor.config.onchange = (html) => {
                this.$emit('change', html)
            }
            this.editor.create()
            this.editor.txt.html(this.context)
            if(this.disable){
                this.editor.disable()
            }
        },
    },
    mounted() {
        this.initEdit()
    }
}
</script>

<style scoped>

</style>
