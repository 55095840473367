<template>
    <div class="dialog-container">
        <el-steps :active="step" finish-status="success" simple style="margin-top: 20px">
            <el-step title="菜品基本信息"></el-step>
            <el-step title="菜品配料"></el-step>
            <el-step title="菜品图片"></el-step>
        </el-steps>
        <br>
        <el-form v-if="step===1" :model="formData" ref="addForm" :rules="formRules" label-width="100px">
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-form-item prop="name" label="菜品名称">
                        <el-input placeholder="请输入菜品名称" v-model="formData.name" type="text"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="goodsType" label="菜品类型">
                        <el-select v-model="formData.goodsType" placeholder="请选择菜品类型" class="inner-input">
                            <el-option
                                    v-for="item in goodsTypes"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="price" label="售价(￥)">
                        <el-input placeholder="请输入售价" v-model="formData.price" type="text"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="marketPrice" label="市场价">
                        <el-input placeholder="请输入市场价" v-model="formData.marketPrice" type="text"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="saledNum" label="已售出">
                        <el-input placeholder="请输入已售出数量" v-model.number="formData.saledNum" type="text"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="storeNum" label="库存">
                        <el-input placeholder="请输入库存" v-model.number="formData.storeNum" type="text"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item prop="deadline" label="商品有效期">
                        <el-date-picker
                                value-format="yyyy-MM-dd"
                                class="inner-input"
                                v-model="formData.deadline"
                                type="date"
                                placeholder="选择商品有效期">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="isOnSale" label="上架状态">
                        <el-select v-model="formData.isOnSale" placeholder="请选择上架状态" class="inner-input">
                            <el-option
                                    v-for="item in statusOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="storeDefault" label="每日库存">
                        <el-input placeholder="请输入每日库存" v-model.number="formData.storeDefault" type="text"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="note" label="菜品标签">
                        <el-select v-model="formData.tagIds" placeholder="请选择菜品标签"
                                   class="inner-input">
                            <el-option
                                    v-for="item in tagsOptions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item prop="note" label="备注">
                        <el-input placeholder="请输入备注" v-model="formData.note" type="text"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="sortNum" label="排序">
                        <el-input placeholder="请输入排序值" v-model.number="formData.sortNum" type="text"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-form-item>
                <el-button @click="handleNextStep" type="primary" :loading="loading">下一步</el-button>
                <el-button @click="handleCancel">取消</el-button>
            </el-form-item>
        </el-form>

        <el-form v-if="step===2" :model="formData" ref="addForm" :rules="formRules" label-width="100px">
            <el-form-item prop="materialIds" label="菜品物料">
                <el-select v-model="formData.materialIds" multiple placeholder="请选择菜品物料" @change="handleSelectMaterial"
                           class="inner-input">
                    <el-option
                            v-for="item in materialOptions"
                            :key="item.materialId"
                            :label="item.categoryName+'-'+item.name"
                            :value="item.materialId">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-table
                    :data="formData.multipleSelection"
                    tooltip-effect="dark"
                    style="width: 100%"
            >
                <el-table-column
                        type="selection"
                        width="55">
                </el-table-column>
                <el-table-column
                        label="物料类型"
                        prop="categoryName"
                        show-overflow-tooltip
                >

                </el-table-column>
                <el-table-column
                        label="名称"
                        prop="name"
                        show-overflow-tooltip
                >

                </el-table-column>

                <el-table-column
                        prop="dosage"
                        label="消耗数量"
                        show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-form-item :prop="'multipleSelection.'+scope.$index+'.dosage'" label-width="0"
                                      :rules="[
                                           {required: true, message: '请输入消耗数量', trigger: ['blur', 'change']}
                                      ]">
                            <el-input maxlength="100" placeholder="请输入消耗数量"
                                      size="small"
                                      v-model="scope.row.dosage"
                                      min="0"
                                      auto-complete="off" type="number"></el-input>
                        </el-form-item>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="unit"
                        label="单位"
                        show-overflow-tooltip>
                </el-table-column>
            </el-table>
            <br>
            <el-form-item>
                <el-button @click="handlePrev" type="primary">上一步</el-button>
                <el-button @click="handleNextStep" type="primary" :loading="loading">下一步</el-button>

                <el-button @click="handleCancel">取消</el-button>
            </el-form-item>
        </el-form>
        <el-form v-if="step===3" :model="formData" ref="addForm" :rules="formRules" label-width="100px">
            <el-row>
                <el-col :span="24">
                    <el-form-item prop="picUrl" label="封面大图">
                        <el-upload
                                class="avatar-uploader"
                                accept="image/gif,image/jpeg,image/png"
                                :action="''"
                                :show-file-list="false"
                                :http-request="uploadImage"
                        >
                            <img v-if="formData.picUrl" :src="formData.picUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </el-col>

<!--                <el-col :span="24">-->
<!--                    <el-form-item prop="banner" label="套餐Banner图片">-->
<!--                        <el-upload-->
<!--                                :action="''"-->
<!--                                list-type="picture-card"-->
<!--                                :auto-upload="true"-->
<!--                                :file-list="formData.banner"-->
<!--                                :http-request="uploadBannerImage">-->
<!--                            <i slot="default" class="el-icon-plus"></i>-->
<!--                            <div slot="file" slot-scope="{file}">-->
<!--                                <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">-->
<!--                                <div class="el-upload-list__item-actions">-->
<!--                                            <span class="el-upload-list__item-delete" @click="handleRemove(file)">-->
<!--                                                <i class="el-icon-delete"></i>-->
<!--                                            </span>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </el-upload>-->
<!--                    </el-form-item>-->
<!--                </el-col>-->
                <el-col :span="24">
                    <el-form-item prop="description" label="菜品描述">
                        <editor :context="formData.description" @change="changeContext"></editor>
                    </el-form-item>
                </el-col>

            </el-row>

            <el-form-item>
                <el-button @click="handlePrev" type="primary">上一步</el-button>
                <el-button type="primary" @click="handleSubmit">确定</el-button>
                <el-button @click="handleCancel">取消</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import editor from '@/components/editor'

import * as foodManager from "@/api/foodManager";
import {uploadImg} from "@/api";
const statusOptions = [
    {
        label: "上架",
        value: 1
    },
    {
        label: '下架',
        value: -1
    }
]
export default {
    name: "addFoods",
    components: {editor},
    data() {
        return {
            step: 1,
            loading: false,
            formData: {
                goodsType: '',
                name: '',
                picUrl: '',
                price: '',
                marketPrice: '',
                saledNum: '',
                storeNum: '',
                deadline: '',
                siteId: '',
                tagIds: '',
                code: '',
                isOnSale: 1,
                sortNum: 0,
                description: '',
                banner: [],
                sharingPosters: '',
                multipleSelection: []
            },
            formRules: {
                siteId: [
                    {required: true, message: '请选择商品类型', trigger: ['blur', 'change']}
                ],
                materialIds: [
                    {required: true, message: '请选择菜品物料', trigger: ['blur', 'change']}
                ],
                description: [
                    {required: true, message: '请输入菜品描述', trigger: ['blur', 'change']}
                ],
                goodsType: [
                    {required: true, message: '请选择商品类型', trigger: ['blur', 'change']}
                ],
                name: [
                    {required: true, message: '请输入商品名称', trigger: ['blur', 'change']}
                ],
                isOnSale: [
                    {required: true, message: '请选择上架状态', trigger: ['blur', 'change']}
                ],
                sortNum: [
                    {required: true, message: '请输入排序编号', trigger: ['blur', 'change']}
                ],
                picUrl: [
                    {required: true, message: '请上传图片', trigger: ['blur', 'change']}
                ],
                // banner: [
                //     {required: true, message: '请上传菜品banner图片', trigger: ['blur', 'change']}
                // ],
                price: [
                    {required: true, message: '请输入售价', trigger: ['blur', 'change']}
                ],
                marketPrice: [
                    {required: true, message: '请输入市场价', trigger: ['blur', 'change']}
                ],
                saledNum: [
                    {required: true, message: '请输入已售数量', trigger: ['blur', 'change']}
                ],
                storeNum: [
                    {required: true, message: '请输入库存数', trigger: ['blur', 'change']}
                ],
                deadline: [
                    {required: true, message: '请选择有效时间', trigger: ['blur', 'change']}
                ]
            },
            statusOptions,
            goodsTypes: [],
            materialOptions: [],
            tagsOptions: [],
            multipleSelection:[]
        }
    },
    methods: {
        handleSelectMaterial(val) {
            this.formData.multipleSelection = this.materialOptions.filter(item => val.indexOf(item.materialId) > -1)
        },
        handlePrev() {
            if (this.step < 1) return false
            this.step -= 1
        },
        handleNextStep() {
            this.$refs["addForm"].validate(valid => {
                if (valid) {
                    this.step += 1
                }
            });
        },
        changeContext(html) {
            console.log(this.formData)
            this.formData.description = html
        },
        async getGoodsType() {
            try {
                let {data} = await foodManager.foodTypeData()
                this.goodsTypes = data.list.filter(item => item.code.indexOf('A') > -1)
                console.log(data)
            } catch (e) {

            }
        },
        async getFoodMaterialList() {
            try {
                let {data} = await foodManager.getFoodMaterialList()
                data.goodsMaterialList.map(item => {
                    item.dosage = null
                    return item
                })
                this.materialOptions = data.goodsMaterialList
                console.log('getFoodMaterialList', data)
            } catch (e) {
                console.error(e)
            }
        },
        async getTags() {
            try {
                let {data} = await foodManager.tagsDataNoPage()
                this.tagsOptions = data
                console.log('tagsDataNoPage', data)
            } catch (e) {

            }
        },
        async uploadImage({file}) {
            try {
                // 根据后台需求数据格式
                const formData = new FormData()
                // 文件对象
                formData.append('filedata', file)
                let {data} = await uploadImg(formData)
                this.formData.picUrl = data.url
                console.log(data)

            } catch (e) {
                console.error(e)
            }
        },
        async uploadBannerImage({file}) {
            try {
                // 根据后台需求数据格式
                const formData = new FormData()
                // 文件对象
                formData.append('filedata', file)
                let {data} = await uploadImg(formData)
                this.formData.banner.push(data)
                console.log(data)

            } catch (e) {

            }
        },
        handleRemove(file, fileList) {
            console.log(this.formData)
            let index = this.formData.banner.findIndex(item => item.url === file.url)
            this.formData.banner.splice(index, 1)
        },
        handleSubmit() {
            this.$refs["addForm"].validate(async (valid) => {
                console.log(this.formData)
                if (valid) {//表单验证通过
                    try {

                        let formData = {}

                        for (let key in this.formData) {
                            if (this.formData[key] !== '' && this.formData[key] !== undefined && this.formData[key] !== null && key !== 'materialIds' && key !== 'multipleSelection' && key !== 'tagList') {
                                formData[key] = this.formData[key]
                            }
                        }
                        let materialIds = JSON.stringify(this.formData.multipleSelection)
                        // formData.banner = this.formData.banner.map(item => {
                        //     return item.url
                        // })
                        // formData.banner = formData.banner.toString()
                        let data = await foodManager.goodsAddDo(materialIds, formData)
                        this.$message({
                            message: data.msg,
                            type: 'success'
                        });
                        this.$emit('success')
                    } catch (e) {
                        console.error(e)
                    }
                }
            });
        },
        handleCancel() {
            this.$emit('cancel')
        },
    },
    mounted() {
        this.getGoodsType()
        this.getFoodMaterialList()
        this.getTags()
    }
}
</script>
<style scoped lang="less">
.dialog-container {
    .avatar-uploader {
        display: inline-block;
        width: 146px;

        /deep/ .el-upload {
            width: 146px;
            height: 146px;
            line-height: 146px;
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }

        .el-upload:hover {
            border-color: #409EFF;
        }

        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 146px;
            height: 146px;
            line-height: 146px;
            text-align: center;
            border: 1px dashed #dedede;
            border-radius: 10px;
        }

        .avatar {
            width: 146px;
            height: 146px;
            display: block;
        }
    }

}

</style>

