<template>
    <el-upload
            class="avatar-uploader"
            :action="action"
            :show-file-list="false"
            :headers="{'c-token':token}"
            name="filedata"
            :on-success="handleSuccess">
        <img v-if="imgUrl" :src="imgUrl" class="avatar-img">
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
</template>

<script>
    import {mapGetters} from 'vuex'
    export default {
        name: "index",
        props:{
            imgUrl: {
                type:String,
                default:''
            },
        },
        data() {
            return {
                action: process.env.VUE_APP_BASEAPI + 'open/file/upload/image'
            };
        },
        computed: {
            ...mapGetters(['token']),
        },
        methods:{
            handleSuccess(res, file) {
                // this.imgUrl = URL.createObjectURL(file.raw);
                this.$emit('success',res)
            }
        },
        mounted(){
            console.log(this.imgUrl)
        }

    }
</script>

<style lang="less">
    .avatar-uploader {
        height: 100%;
        width: 100%;
        position: relative;
        &.el-upload:hover{
            border-color: #409EFF;
        }
        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
        }
        .el-upload {
            height: 100%;
            display: flex !important;
            justify-content: center;
            align-items: center;
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        .avatar-img {
            height: 100%;
            width: 100%;
            display: block;
        }
    }

</style>
